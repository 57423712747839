import React,{useEffect,useState} from 'react'
import Footer from '../Layouts/SubLayout/Footer'
import UserHeader from '../Layouts/SubLayout/UserHeader'
import api from "../../Environment";
import ToastDemo from "../Helper/toaster";
import Helper from "../Helper/helper";
import './mymovies.css'
import Slider from "../SliderView/MainSlider";

export default function MyMovies(props) {

    let [moviesRentedList,setRetendMoviesList] = useState([]);
    let [moviesPurchasedList,setPurchasedMoviesList] = useState([]);

    let [checked,setChecked] = useState(false);
    const DATE_OPTIONS = {
        year: "numeric",
        month: "short",
    };

    const DATE_OPTIONS2 = {
        day:"numeric",
        month: "short",
        year: "numeric",
    };

    useEffect(()=>{
        getMyMovies('no')
        getMyMovies('yes')
    },[])

    const getMyMovies = (is_buy) =>{
        let inputData = {
            skip : 0,
            take: 20,
            is_buy:is_buy
        }
        api
        .postMethod("v4/ppv_list", inputData)
        .then((response) => {
            console.log(response)
            if (response.data.success) {
                if(is_buy === 'no')
                    setRetendMoviesList(response.data.data)
                if(is_buy === 'yes')
                    setPurchasedMoviesList(response.data.data)
            }
        });
    }

    return (

        <div className="main p-40">
            <div className="main-slidersec">
                <h3 className="">
                    My Rented Movies
                    <i className="fas fa-angle-right ml-2" />
                </h3>
                    {
                        moviesRentedList && moviesRentedList.length > 0 ?
                            <Slider>
                                {moviesRentedList.map((movie) => (
                                    <Slider.Item
                                        movie={movie}
                                        key={movie.admin_video_id}
                                        checked={checked}
                                        expiry={true}
                                    >
                                        item1
                                    </Slider.Item>
                                ))}
                            </Slider>
                        : 
                        <p>You don't have any movie for rent</p>
                    }
            </div>
            <div className="main-slidersec mt-4">
                <h3 className="">
                    My Purchased Movies
                    <i className="fas fa-angle-right ml-2" />
                </h3>
                    {
                        moviesPurchasedList && moviesPurchasedList.length > 0 ?
                            <Slider>
                                {moviesPurchasedList.map((movie) => (
                                    <Slider.Item
                                        movie={movie}
                                        key={movie.admin_video_id}
                                        checked={checked}
                                        expiry={false}
                                        is_buy={true}
                                    >
                                        item1
                                    </Slider.Item>
                                ))}
                            </Slider>
                        : 
                        <p>No Movie Found</p>
                    }
            </div>
            <div className="height-100" />
        </div>

        // <div className="my-movies-sec">
        //     <UserHeader />
        //     <div className="main-sec-content p-40">
        //         <div className="container-fluid">
        //             <div className="row">
        //                 <div className="col-12">
        //                     <h3>Purchased</h3>
        //                 </div>
        //                 {/* <div className="col-12">
        //                     <div className=" movie-wrapper-container-expo row p-40">
        //                         <div className="movie-wrapper-container">
        //                             <div className="movie-wrapper">
        //                                 <img className="movie-img" src="https://images.pexels.com/photos/265685/pexels-photo-265685.jpeg?cs=srgb&dl=pexels-pixabay-265685.jpg&fm=jpg" alt="" />
        //                                 <div className="movie-wrapper-content">
        //                                     <h4 className="thumb-tilte">Los Hermanos - The Brothers</h4>
        //                                     <h5 className="thumb-details">
        //                                         <span className="green-clr">May 2022</span>
        //                                         <span className="grey-box">10
        //                                             <i className="fas fa-plus small"></i> / 0 <span class="small">Views</span>
        //                                         </span>
        //                                     </h5>
        //                                     <p className="thumb-desc">Separated by geopolitics, united by music, two Cuban brothers long to collaborate </p>
        //                                 </div>

        //                             </div>
        //                         </div>
        //                         <div className="movie-wrapper-container">
        //                             <div className="movie-wrapper">
        //                                 <img className="movie-img" src="https://images.pexels.com/photos/265685/pexels-photo-265685.jpeg?cs=srgb&dl=pexels-pixabay-265685.jpg&fm=jpg" alt="" />
        //                                 <div className="movie-wrapper-content">
        //                                     <h4 className="thumb-tilte">Los Hermanos - The Brothers</h4>
        //                                     <h5 className="thumb-details">
        //                                         <span className="green-clr">May 2022</span>
        //                                         <span className="grey-box">10
        //                                             <i className="fas fa-plus small"></i> / 0 <span class="small">Views</span>
        //                                         </span>
        //                                     </h5>
        //                                     <p className="thumb-desc">Separated by geopolitics, united by music, two Cuban brothers long to collaborate </p>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                         <div className="movie-wrapper-container">
        //                             <div className="movie-wrapper">
        //                                 <img className="movie-img" src="https://images.pexels.com/photos/265685/pexels-photo-265685.jpeg?cs=srgb&dl=pexels-pixabay-265685.jpg&fm=jpg" alt="" />

        //                             </div>
        //                         </div>
        //                         <div className="movie-wrapper-container">
        //                             <div className="movie-wrapper">
        //                                 <img className="movie-img" src="https://images.pexels.com/photos/265685/pexels-photo-265685.jpeg?cs=srgb&dl=pexels-pixabay-265685.jpg&fm=jpg" alt="" />

        //                             </div>
        //                         </div>
        //                         <div className="movie-wrapper-container">
        //                             <div className="movie-wrapper">
        //                                 <img className="movie-img" src="https://images.pexels.com/photos/265685/pexels-photo-265685.jpeg?cs=srgb&dl=pexels-pixabay-265685.jpg&fm=jpg" alt="" />

        //                             </div>
        //                         </div>
        //                         <div className="movie-wrapper-container">
        //                             <div className="movie-wrapper">
        //                                 <img className="movie-img" src="https://images.pexels.com/photos/265685/pexels-photo-265685.jpeg?cs=srgb&dl=pexels-pixabay-265685.jpg&fm=jpg" alt="" />

        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div> */}
        //                 <div className="container-one">
        //                     <a href="#animals" className="item-one"><img src="https://placeimg.com/640/480/animals" alt="Animals" />


        //                     </a>
        //                     <a href="#architecture" className="item-one"><img src="https://placeimg.com/640/480/architecture" alt="Architecture" />
        //                         <div className="movie-wrapper-content">
        //                             <h4 className="thumb-tilte">Los Hermanos - The Brothers</h4>
        //                             <h5 className="thumb-details">
        //                                 <span className="green-clr">May 2022</span>
        //                                 <span className="grey-box">10
        //                                     <i className="fas fa-plus small"></i> / 0 <span class="small">Views</span>
        //                                 </span>
        //                             </h5>
        //                             <p className="thumb-desc">Separated by geopolitics, united by music, two Cuban brothers long to collaborate </p>
        //                         </div>
        //                     </a>
        //                     <a href="#nature" className="item-one"><img src="https://placeimg.com/640/480/nature" alt="Nature" /></a>
        //                     <a href="#people" className="item-one"><img src="https://placeimg.com/640/480/people" alt="People" /></a>
        //                     <a href="#tech" className="item-one"><img src="https://placeimg.com/640/480/tech" alt="Tech" /></a>
        //                 </div>

        //             </div>
        //             <div className="row p-40">
        //                 <div className="col-12">
        //                     <h3>Rented</h3>
        //                 </div>
        //                 <div className="container-one">
        //                     {
        //                         moviesList && moviesList.length > 0 ?
        //                             <Slider>
        //                                 {moviesList.map((movie) => (
        //                                 <Slider.Item
        //                                     movie={movie}
        //                                     key={movie.admin_video_id}
        //                                     checked={checked}
        //                                 >
        //                                     item1
        //                                 </Slider.Item>
        //                                 ))}
        //                             </Slider>
        //                         : 
        //                         null
        //                     }
                        
        //                     {/* {
        //                         moviesList && moviesList.length > 0  ? 
        //                             moviesList.map((movie,index)=>{
        //                                 return <a href="javascript:void(0)" onClick={()=>handlePlayVideo(movie.admin_video_id)} className="item-one">
        //                                             <img src={movie.default_image} alt="Tech" />
        //                                             <div className="movie-wrapper-content">
        //                                                 <h4 className="thumb-tilte">{movie.title}</h4>
        //                                                 <h5 className="thumb-details">
        //                                                     <span className="green-clr">
        //                                                     {new Date(movie.publish_time).toLocaleDateString(
        //                                                         "en-US",
        //                                                         DATE_OPTIONS
        //                                                     )}
        //                                                     </span>
        //                                                     <span className="grey-box">10
        //                                                         <i className="fas fa-plus small"></i> / {movie.watch_count ? movie.watch_count : 0}{" "} <span class="small">Views</span>
        //                                                     </span>
        //                                                 </h5>
        //                                                 <p className="thumb-title text-red">Expiry Date : {" "}
        //                                                         {new Date(movie.expiry_date).toLocaleDateString(
        //                                                             "en-US",
        //                                                             DATE_OPTIONS2
        //                                                         )}</p>
        //                                                 <p className="thumb-desc">
        //                                                 {movie.description}
        //                                                 </p>
        //                                             </div>
        //                                         </a>
        //                             })
        //                         : <p>No Video Found</p>
        //                     } */}
        //                 </div>
                        
        //                 {/* <div className="col-12">
        //                     <div className=" movie-wrapper-container-expo row p-40">
        //                         <div className="movie-wrapper-container">
        //                             <div className="movie-wrapper">
        //                                 <img className="movie-img" src="https://images.pexels.com/photos/265685/pexels-photo-265685.jpeg?cs=srgb&dl=pexels-pixabay-265685.jpg&fm=jpg" alt="" />
        //                                 <div className="movie-wrapper-content">
        //                                     <h4 className="thumb-tilte">Los Hermanos - The Brothers</h4>
        //                                     <h5 className="thumb-details">
        //                                         <span className="green-clr">May 2022</span>
        //                                         <span className="grey-box">10
        //                                             <i className="fas fa-plus small"></i> / 0 <span class="small">Views</span>
        //                                         </span>
        //                                     </h5>
        //                                     <p className="thumb-desc">Separated by geopolitics, united by music, two Cuban brothers long to collaborate </p>
        //                                 </div>

        //                             </div>
        //                         </div>
        //                         <div className="movie-wrapper-container">
        //                             <div className="movie-wrapper">
        //                                 <img className="movie-img" src="https://images.pexels.com/photos/265685/pexels-photo-265685.jpeg?cs=srgb&dl=pexels-pixabay-265685.jpg&fm=jpg" alt="" />
        //                                 <div className="movie-wrapper-content">
        //                                     <h4 className="thumb-tilte">Los Hermanos - The Brothers</h4>
        //                                     <h5 className="thumb-details">
        //                                         <span className="green-clr">May 2022</span>
        //                                         <span className="grey-box">10
        //                                             <i className="fas fa-plus small"></i> / 0 <span class="small">Views</span>
        //                                         </span>
        //                                     </h5>
        //                                     <p className="thumb-desc">Separated by geopolitics, united by music, two Cuban brothers long to collaborate </p>
        //                                 </div>
        //                             </div>
        //                         </div>
        //                         <div className="movie-wrapper-container">
        //                             <div className="movie-wrapper">
        //                                 <img className="movie-img" src="https://images.pexels.com/photos/265685/pexels-photo-265685.jpeg?cs=srgb&dl=pexels-pixabay-265685.jpg&fm=jpg" alt="" />

        //                             </div>
        //                         </div>
        //                         <div className="movie-wrapper-container">
        //                             <div className="movie-wrapper">
        //                                 <img className="movie-img" src="https://images.pexels.com/photos/265685/pexels-photo-265685.jpeg?cs=srgb&dl=pexels-pixabay-265685.jpg&fm=jpg" alt="" />

        //                             </div>
        //                         </div>
        //                         <div className="movie-wrapper-container">
        //                             <div className="movie-wrapper">
        //                                 <img className="movie-img" src="https://images.pexels.com/photos/265685/pexels-photo-265685.jpeg?cs=srgb&dl=pexels-pixabay-265685.jpg&fm=jpg" alt="" />

        //                             </div>
        //                         </div>
        //                         <div className="movie-wrapper-container">
        //                             <div className="movie-wrapper">
        //                                 <img className="movie-img" src="https://images.pexels.com/photos/265685/pexels-photo-265685.jpeg?cs=srgb&dl=pexels-pixabay-265685.jpg&fm=jpg" alt="" />

        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div> */}
                        

        //             </div>
        //         </div>
        //     </div>
        //     <Footer />
        // </div>
    )
}
