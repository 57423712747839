import React, {Component} from 'react';
import configuration from "react-global-configuration";

import {Link} from 'react-router-dom';

class AuthHeader extends Component {

    constructor(props) {

        super(props);

        this.state = {
            
            isAuthenticated : this.props.data,
            
        };

    }

    componentDidMount() {

        // Call api function

    }

    render() {

        return (
            <div className="landing-page-header">
                <Link className="navbar-brand abs" to="/home">
                    <img
                        src={configuration.get("configData.site_logo")}
                        className="logo-img desktop-logo"
                        alt={configuration.get("configData.site_name")}
                    />
                    <img
                        src={configuration.get("configData.site_icon")}
                        className="logo-img mobile-logo"
                        alt={configuration.get("configData.site_name")}
                    />
                </Link>
                <a style={{}} href="/login" className="btn btn-danger movie-single-signin-btn">sign in</a>
            </div>
        );

    }

}


export default AuthHeader;

