import React from 'react'
import AuthHeader from '../../Layouts/SubLayout/AuthHeader'
import UserHeader from '../../Layouts/SubLayout/UserHeader'
import './MovieSingle.css'

export default function MovieSingle() {
  return (
    <div className="moviesSingle">
        <AuthHeader/>
        <div className="movie-container ">
            <img className="movie-img-primary" src="./images/transformers.jpg" alt="" />
            <div className="movie-primary-detail-container">
                <div className="movie-primary-detail-items">
                  <img className="movie-logo" src="https://occ-0-4344-64.1.nflxso.net/dnm/api/v6/LmEnxtiAuzezXBjYXPuDgfZ4zZQ/AAAABYpAVe8hkKW8xJ016aS8XMNaOaPTFayvDeZ-kVZHS5dUSnoy8h9uOmKVdQzCmV3rQYpnCvVc7ts8mbFuoLntWZ-Wb14OhZnE1nntLSspg-QE.png?r=4ae" alt="" />
                <h1>Transformers: Age of Extinction</h1>
                <p className="movie-meta"> <span>2014 |</span> <span>U/A 13+ |</span>  <span>2h 46m |</span>  <span>Sci-Fi Movies</span>  </p>
                <p className="movie-description">
                Five years after Chicago was destroyed, humans have turned against all robots. But a single dad and inventor resurrects one that may save the world.
                </p>
                <p className="starring-meta"> <span style={{color:"#a3a3a3"}}>Starring : </span> Mark Wahlberg,Stanley Tucci,Kelsey Grammer </p>
                </div>
               

            </div>
           
            <div className="extra-container">
          <div>
          <img src="https://admin-watch.antidotefilms.com.au/uploads/s…6-31-62e6f727c8600b037b5b7de33c43542262375b83.png" className="website-logo" alt="" />
          <h4>Watch all you want.</h4>
          </div>
        <div>
          <button className="btn btn-danger">Join Now</button>
        </div>
        </div>
        </div>
      
    </div>
  )
}
